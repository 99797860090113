import React from "react";

import '../css/footer.css'

function Footer(){
    return (
        <div className="footer">
            © 2023 Frederick Donghyeon Go. All Rights Reserved. 
        </div>
    )
};

export default Footer; 